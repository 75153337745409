import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

  static get STUDENT_URL(): string {
    return environment.appUrl + 'student/';
  }

  /*static get UNIVERSITY_URL(): string {
    return environment.appUrl + 'university/';
  }*/

  static get LOGIN(): string {
    return AppUrl.STUDENT_URL + 'login';
  }

  static VERIFY_ENROLLMENT_NO(): string {
    return AppUrl.STUDENT_URL + 'verify-enrollment-no';
  }

  static RESET_PASSWORD(): string {
    return AppUrl.STUDENT_URL + 'reset-password';
  }

  static get SELF(): string {
    return AppUrl.STUDENT_URL + 'self';
  }

  static UPDATE_SELF(): string {
    return AppUrl.STUDENT_URL + 'update-self';
  }

  static LOGOUT(): string {
    return AppUrl.STUDENT_URL + 'logout';
  }

  static FILE_UPLOAD(): string {
    return AppUrl.STUDENT_URL + 'file-upload';
  }

  static FILE_UPLOAD_ISSUE(): string {
    return AppUrl.STUDENT_URL + 'file-upload-issue';
  }

  static UPDATE_USER_PASSWORD(): string {
    return AppUrl.STUDENT_URL + 'update-user-password';
  }

  static COURSES(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'course/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'course';
    }
  }

  static CENTER_BRANCH(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'center-branch/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'center-branch';
    }
  }

  static CENTER_BRANCH_PASSWORD(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'center-branch-password/' + id;
    }
  }

  static CENTER_BRANCH_STATUS(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'center-branch-status/' + id;
    }
  }

  static GET_BRANCH_SHARE_PERCENTAGE(): string {
    return AppUrl.STUDENT_URL + 'branch-share-percentage';
  }

  static UPDATE_BRANCH_SHARE_PERCENTAGE(user_id, session_id): string {
    return AppUrl.STUDENT_URL + 'update-branch-share-percentage/' + user_id + '/' + session_id;
  }

  static STATES(): string {
    return AppUrl.STUDENT_URL + 'states';
  }

  static STUDENT(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'student/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'student';
    }
  }


  static ADMIT_CARD_DOWNLOAD_STATUS(id): string {
    return AppUrl.STUDENT_URL + 'admit_card_download_status/' + id;
  }

  static RESEND_OTP_STUDENT(): string {
    return AppUrl.STUDENT_URL + 'resend-otp-student';
  }

  static VERIFY_OTP_STUDENT(): string {
    return AppUrl.STUDENT_URL + 'verify-otp-student';
  }

  static SESSIONS(): string {
    return AppUrl.STUDENT_URL + 'session';
  }

  static DEFAULT_SESSION(): string {
    return AppUrl.STUDENT_URL + 'default-session';
  }

  static PERSONAL_INFO(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'personal-info/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'personal-info';
    }
  }

  static TRANSLATE_TEXT(): string {
    return AppUrl.STUDENT_URL + 'translate-text';
  }

  static PERSONAL_INFO_PHOTOGRAPH_UPDATE(): string {
    return AppUrl.STUDENT_URL + 'personal-info-photograph';
  }

  static PERSONAL_INFO_GOVT_PHOTO_ID_UPDATE(): string {
    return AppUrl.STUDENT_URL + 'personal-info-govt-photo-id-proof';
  }

  static ADDRESS(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'address/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'address';
    }
  }

  static ACADEMIC_DETAILS(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'academic-details/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'academic-details';
    }
  }

  static ACADEMIC_DETAILS_TENTH_CERTIFICATE_UPDATE(): string {
    return AppUrl.STUDENT_URL + 'academic-details-tenth-certificate';
  }

  static ACADEMIC_DETAILS_TWELTH_CERTIFICATE_UPDATE(): string {
    return AppUrl.STUDENT_URL + 'academic-details-twelth-certificate';
  }

  static MORE_QUALIFICATION(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'more-qualification/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'more-qualification';
    }
  }

  static MORE_QUALIFICATION_GRADUATION_CERTIFICATE_UPDATE(): string {
    return AppUrl.STUDENT_URL + 'more-qualification-graduation-certificate';
  }

  static MORE_QUALIFICATION_POST_GRADUATION_CERTIFICATE_UPDATE(): string {
    return AppUrl.STUDENT_URL + 'more-qualification-post-graduation-certificate';
  }

  static MORE_QUALIFICATION_OTHER_QUALIFICATION_CERTIFICATE_UPDATE(): string {
    return AppUrl.STUDENT_URL + 'more-qualification-other-qualification-certificate';
  }

  static PROGRAMS(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'program/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'program';
    }
  }

  static PCB_MAPPINGS(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'pcb-mapping/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'pcb-mapping';
    }
  }

  static PCBS_MAPPINGS(): string {
    return AppUrl.STUDENT_URL + 'subject-mapping';
  }

  static PCBS_ASSIGNMENT_MAPPINGS(): string {
    return AppUrl.STUDENT_URL + 'subject-mapping-assignment';
  }

  static COURSE_DETAILS(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'course-details/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'course-details';
    }
  }

  static PAYMENT(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'pay-fees/' + id;
    }
  }

  static COURSE_CURRICULUM(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'course-curriculum/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'course-curriculum';
    }
  }


  static SUPPORT_TICKET(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'support-ticket/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'support-ticket';
    }
  }

  static SUBMIT_ISSUES(unique_id?): string {
    if (unique_id) {
      return AppUrl.STUDENT_URL + 'submit-issues/' + unique_id;
    }
  }

  static ISSUES(unique_id?): string {
    if (unique_id) {
      return AppUrl.STUDENT_URL + 'issues/' + unique_id;
    } else {
      return AppUrl.STUDENT_URL + 'issues';
    }
  }

  static ISSUE_DETAILS(unique_id): string {
    return AppUrl.STUDENT_URL + 'issues/' + unique_id;
  }

  static ISSUE_STATUS(unique_id): string {
    return AppUrl.STUDENT_URL + 'issues-status/' + unique_id;
  }

  static SUBMIT_DOCS(unique_id?): string {
    if (unique_id) {
      return AppUrl.STUDENT_URL + 'submit-docs/' + unique_id;
    }
  }

  static DOCS(unique_id?): string {
    if (unique_id) {
      return AppUrl.STUDENT_URL + 'docs/' + unique_id;
    } else {
      return AppUrl.STUDENT_URL + 'docs';
    }
  }

  static DOC_DETAILS(unique_id): string {
    return AppUrl.STUDENT_URL + 'docs/' + unique_id;
  }

  static TOTAL_DOC(): string {
    return AppUrl.STUDENT_URL + 'docs-count';
  }
  static DOC_IMAGE(unique_id): string {
    return AppUrl.STUDENT_URL + 'doc-image/' + unique_id;
  }

  static NOTIFICATION(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'notify/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'notify';
    }
  }

  static NOTIFICATION_STATUS(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'notify-status/' + id;
    }
  }

  static NOTIFICATION_SELF(): string {
    return AppUrl.STUDENT_URL + 'notify-self';
  }

  static NOTIFICATION_COUNT(): string {
    return AppUrl.STUDENT_URL + 'notify-count';
  }

  static PROFILE_IMAGE(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'profile-image/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'profile-image';
    }
  }

  static SELF_PROFILE_PASSWORD(): string {
    return AppUrl.STUDENT_URL + 'update-user-password';
  }

  static ASSIGNMENTS_SUBMISSION(): string {
    return AppUrl.STUDENT_URL + 'assignment-submission';
  }

  static SCHEDULE_EXAM_SUBJECTS(): string {
    return AppUrl.STUDENT_URL + 'schedule-exam-subjects';
  }

  static SCHEDULE_EXAM_RESULTS(): string {
    return AppUrl.STUDENT_URL + 'schedule-exam-results';
  }

  static SCHEDULE_EXAM(id): string {
    return AppUrl.STUDENT_URL + 'schedule-exam/' + id;
  }

  static QUESTION_PAPER(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'question-paper/' + id;
    }
  }

  static EXAM_STATUS(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'exam-status/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'exam-status';
    }
  }

  static EXAM_ANSWER_OBJECTIVE_SUBMISSION(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'exam-answer-objective/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'exam-answer-objective';
    }
  }

  static EXAM_ANSWER_SUBJECTIVE_SUBMISSION(id?): string {
    if (id) {
      return AppUrl.STUDENT_URL + 'exam-answer-subjective/' + id;
    } else {
      return AppUrl.STUDENT_URL + 'exam-answer-subjective';
    }
  }

  static DELETE_EXAM_ANSWER_OBJECTIVE_SUBMISSION(schedule_exam_id, question_id): string {
    if (schedule_exam_id && question_id) {
      return AppUrl.STUDENT_URL + 'exam-answer/' + schedule_exam_id + '/' + question_id;
    }
  }

  static DELETE_EXAM_ANSWER_SUBJECTIVE_SUBMISSION(schedule_exam_id, question_id): string {
    if (schedule_exam_id && question_id) {
      return AppUrl.STUDENT_URL + 'exam-answer/' + schedule_exam_id + '/' + question_id;
    }
  }

  static EXAM_ANSWER_SUBMISSION(schedule_exam_id): string {
    if (schedule_exam_id) {
      return AppUrl.STUDENT_URL + 'exam-answer/' + schedule_exam_id;
    }
  }
}
