import {HostListener, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {StorageService} from "./storage.service";
import {StudentService} from "./student.service";
import {Helper} from "../constants/helper";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})

export class NavService {
  user: any;
  userData: any;

  constructor(private studentService: StudentService) {
    this.onResize();
    this.user = StorageService.getItem('self');
    if (this.user && this.user.user_id){
      this.getUserDetail();
    }
    if (this.screenWidth < 991) {
      this.collapseSidebarMainMenu = false;
      this.collapseSidebarSecondMenu = true;
    }
  }

  public openclass: boolean = false;
  public screenWidth: any;

  public collapseSidebarMainMenu = false;
  public collapseSidebarSecondMenu = false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth >= 991;

  public fullScreen = false;

  MENUITEMS: Menu[] = [
    {
      title: 'General', path: '/general/home', icon: 'pe-7s-home', type: 'sub', active: true, children:
        [
          {
            title: 'General', type: 'sub', active: true, children: [
              {path: '/general/home', title: 'Home', type: 'link'},
              {path: '/general/application-form', title: 'Application Form', type: 'link'}
            ]
          }
        ],
    },
    {
      title: 'Docs', path: '/docs/home', icon: 'pe-7s-photo-gallery', type: 'sub', active: false, children:
        [
          {
            title: 'Docs', type: 'sub', active: true, children: [
              {path: '/docs/home', title: 'Home', type: 'link'},
              {path: '/docs/id-card', title: 'Id Card', type: 'link'},
              {path: '/docs/admit-card', title: 'Admit Card', type: 'link'},
              {path: '/docs/marksheet', title: 'Marksheet', type: 'link'},
              // {path: '/docs/migration', title: 'Migration', type: 'link'},
              {path: '/docs/original-degree', title: 'Original Degree', type: 'link'},
              // {path: '/docs/transcript', title: 'Transcript', type: 'link'},
              // {path: '/docs/e-marksheet', title: 'E-Marksheet', type: 'link'}
            ]
          }
        ],
    },
  /*  {
      title: 'LMS', path: '/lms/home', icon: 'pe-7s-monitor', type: 'sub', active: false, children:
        [
          {
            title: 'LMS', type: 'sub', active: true, children: [
              {path: '/lms/home', title: 'Home', type: 'link'},
              /!*{path: '/lms/lectures', title: 'Lectures', type: 'link'},
              {path: '/lms/syllabus', title: 'Syllabus', type: 'link'},*!/
              {path: '/lms/slm', title: 'SLMs', type: 'link'},
              {path: '/lms/assignments', title: 'Assignments', type: 'link'},
            ]
          }
        ],
    },*/
  /*  {
      title: 'Exam', path: '/exam/home', icon: 'pe-7s-note2', type: 'sub', active: false, children:
        [
          {
            title: 'Exam', type: 'sub', active: true, children: [
              {path: '/exam/home', title: 'Home', type: 'link'},
              {path: '/exam/admit-card', title: 'Admit Card', type: 'link'},
              {path: '/exam/scheduled-exams', title: 'Scheduled Exam', type: 'link'},
              {path: '/exam/results', title: 'Results', type: 'link'},
              /!*{path: '/exam/e-marksheet', title: 'E - Marksheet', type: 'link'},*!/
              /!*{path: '/exam/results', title: 'Results', type: 'link'},*!/
              /!*{path: '/exam/exam', title: 'Exam', type: 'link'},
              {path: '/exam/form', title: 'Exam Form', type: 'link'},
              {path: '/exam/time-table', title: 'Exam Time Table', type: 'link'}*!/
            ]
          },
        ],
    },*/
    /*{
      title: 'Exam', path: '/exam/home', icon: 'pe-7s-note2', type: 'sub', active: false, children:
        [
          {
            title: 'Exam', type: 'sub', active: true, children: [
              {path: '/exam/home', title: 'Home', type: 'link'},
              {path: '/exam/admit-card', title: 'Admit Card', type: 'link'},
              {path: '/exam/exam', title: 'Exam', type: 'link'},
              {path: '/exam/form', title: 'Exam Form', type: 'link'},
              {path: '/exam/time-table', title: 'Exam Time Table', type: 'link'}
            ]
          },
        ],
    },*/
   /*  {
       title: 'LMS', path: '/lms/home', icon: 'pe-7s-monitor', type: 'sub', active: false, children:
         [
           {
             title: 'LMS', type: 'sub', active: true, children: [
               {path: '/lms/home', title: 'Home', type: 'link'},
              /!* {path: '/lms/lectures', title: 'Lectures', type: 'link'},
               {path: '/lms/syllabus', title: 'Syllabus', type: 'link'},*!/
               {path: '/lms/self-learning-materials', title: 'Self Learning Materials', type: 'link'},
               {path: '/lms/assignments', title: 'Assignments', type: 'link'},
             ]
           }
         ],
     },*/
    /*{
      title: 'Docs', path: '/docs/home', icon: 'pe-7s-photo-gallery', type: 'sub', active: false, children:
        [
          {
            title: 'Docs', type: 'sub', active: true, children: [
              {path: '/docs/home',title: 'Home',  type: 'link'},
              {path: '/docs/id-card', title: 'Id Card', type: 'link'},
              {path: '/docs/marksheet', title: 'Marksheet', type: 'link'},
              {path: '/docs/migration', title: 'Migration', type: 'link'},
              {path: '/docs/original-degree', title: 'Original Degree', type: 'link'},
              {path: '/docs/transcript', title: 'Transcript', type: 'link'}
            ]
          }
        ],
    },*/
   {
      title: 'Exam', path: '/exam/home', icon: 'pe-7s-note2', type: 'sub', active: false, children:
        [
          {
            title: 'Exam', type: 'sub', active: true, children: [
              {path: '/exam/home', title: 'Home', type: 'link'},
             /* {path: '/exam/exam', title: 'Exam', type: 'link'},
              {path: '/exam/form', title: 'Exam Form', type: 'link'},*/
              {path: '/exam/time-table', title: 'Exam Time Table', type: 'link'}
            ]
          },
        ],
    },
    /*{
      title: 'Results', path: '/results/home', icon: 'pe-7s-study', type: 'sub', active: false, children:
        [
          {
            title: 'Results', type: 'sub', active: true, children: [
              {path: '/results/home', title: 'Home', type: 'link'},
              {path: '/results/revaluation', title: 'Revaluation', type: 'link'},
              {path: '/results/result', title: 'Result', type: 'link'}
            ]
          }
        ],
    },*/
    {
      title: 'Notification', path: '/notify/home', icon: 'fas fa-bell', type: 'sub', active: false, children:
        [
          {
            title: 'Notification', type: 'sub', active: true, children: [
              {path: '/notify/home', title: 'Home', type: 'link'},
              /*    {path: '/notify/add', title: 'Notification', type: 'link'},*/
            ]
          }
        ],
    },
    /*{
      title: 'Locker', path: '/locker/home', icon: 'pe-7s-lock', type: 'sub', active: false, children:
        [
          {
            title: 'Digital Locker', type: 'sub', active: true, children: [
              {path: '/locker/home', title: 'Home', type: 'link'}
            ]
          }
        ],
    },*/
    {
      title: 'Support', path: '/support/home', icon: 'pe-7s-headphones', type: 'sub', active: false, children:
        [
          {
            title: 'Support', type: 'sub', active: true, children: [
              {path: '/support/home', title: 'Home', type: 'link'},
              {path: '/support/admission-tickets', title: 'Admission Tickets', type: 'link'},
              {path: '/support/other-tickets', title: 'Other Tickets', type: 'link'},
            ]
          }
        ],
    },
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  getUserDetail() {
    this.studentService.detail(this.user.user_id).subscribe(data => {
      if (data) {
        this.userData = data;
        let hiddenMenus = [
          /*{
            'menu': 'DOCS',
            'hideMenu': true,
            'submenus': ['HOME', 'ID CARD', 'MARKSHEET', 'MIGRATION', 'ORIGINAL DEGREE', 'TRANSCRIPT']
          },*/
          {
            'menu': 'LMS',
            'hideMenu': false,
            'submenus': ['HOME', 'SLMS', 'ASSIGNMENTS']
          },
          /*{
            'menu': 'EXAM',
            'hideMenu': false,
            'submenus': ['HOME', 'ADMIT CARD', 'SCHEDULED EXAM', 'RESULTS']
          },*/
          /*{
            'menu': 'NOTIFY',
            'hideMenu': false,
            'submenus': ['HOME']
          }*/
        ];
        this.MENUITEMS.forEach(menu => {
          if (hiddenMenus && hiddenMenus.length > 0) {
            hiddenMenus.forEach(dtMenu => {
              if (menu && (menu.title.toUpperCase() === dtMenu.menu)) {
                if (dtMenu.menu) {
                  menu['is_hidden'] = (this.userData && !this.userData['enrollment_no'] );
                  /*if (menu['children'] && menu['children'].length > 0 && menu['children'][0]) {
                    if (menu['children'][0] && menu['children'][0]['children'] && menu['children'][0] && menu['children'][0]['children'].length > 0) {
                      menu['children'][0]['children'].forEach(child => {
                        if (Helper.inArray(child.title.toUpperCase(), dtMenu.submenus)) {
                          if (child && child['title'] === 'Student Bulk Upload') {
                            child['is_hidden'] = (this.userData['bulk_upload'] === 'DISALLOW');
                          } else if (child && child['title'] === 'Add Branch') {
                            child['is_hidden'] = (this.userData['center_adding_branch'] === 'DISALLOW');
                          }
                        }
                      });
                    }
                  }*/
                }
              }
            });
          }
        });
      }
    });
  }

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
}
